
import AdminCreate from "../../pages/AdminCreate";
import ClientCreate from "../../pages/ClientCreate";
import ClientContactCreate from "../../pages/ClientContactCreate";
import DeveloperCreate from "../../pages/DeveloperCreate";
import DeveloperRoleCreate from "../../pages/DeveloperRoleCreate";
import RoleCreate from "../../pages/RoleCreate";
import TypeCreate from "../../pages/TypeCreate";
import Home from "../../pages/Home";
import MainNavigation from "./MainNavigation";
import CreatePriority from "../../pages/CreatePriority";
import CreateManager from '../../pages/CreateManager';

import { Route, Switch } from "react-router-dom";


const PortalBody = () => {
  return (
    <div>
      <MainNavigation />
      <Switch>
        <Route path="/" exact>
          <Home />
        </Route>
        <Route path="/create-admin">
          <AdminCreate />
        </Route>
        <Route path="/create-client">
          <ClientCreate />
        </Route>
        <Route path="/create-client-contact">
          <ClientContactCreate />
        </Route>
        <Route path="/create-developer">
          <DeveloperCreate />
        </Route>
        <Route path="/create-developer-role">
          <DeveloperRoleCreate />
        </Route>
        <Route path="/create-role">
          <RoleCreate />
        </Route>
        <Route path="/create-type">
          <TypeCreate />
        </Route>
        <Route path="/create-priority">
          <CreatePriority />
        </Route>
        <Route path="/create-manager">
          <CreateManager />
        </Route>
      </Switch>
    </div>
  );
}

export default PortalBody
