const Home = () => {
  return (
    <div className="col-md-12 text-center" style={{ marginTop: "20%" }}>
      <p className=" display-5 text-info fw-bolder">
        Welcome to
        <span className="text-danger fw-bolder"> HAIS</span> request portal
      </p>
    </div>
  );
};

export default Home;
