import { Link } from "react-router-dom";

const LI = (props) => {
 
  return (
    <li className="nav-item">
      <Link className="nav-link" to={props.to}>
        {props.text}
      </Link>
    </li>
  );
};

export default LI;
