import ReactDOM from "react-dom";
import Login from "./pages/Login";

const App = () => {
  const loginHandler = () => {
    ReactDOM.render(<Login />, document.getElementById("root"));
  };


  return (
    <div className="col-md-12 text-center" style={{ marginTop: "20%" }}>
      <p className=" display-5 text-info fw-bolder">
        Welcome to
        <span className="text-danger fw-bolder"> HAIS</span> request portal
      </p>
      <button
        id="proceed"
        className="btn btn-secondary col-md-1"
        onClick={loginHandler}
      >
        Proceed <i className="far fa-hand-point-right text-info h5"></i>
      </button>
    </div>
  );
};

export default App;
