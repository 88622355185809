import { showAlert } from "../components/Helpers/Utils";

const adminFunc = () => {
  const formData = new FormData(document.getElementById("frmCreateAdmin"));
  createAdmin(formData)
    .then((data) => {
     showAlert(data, "success");
    })
    .catch((error) => {
      console.log(error);
    });
};

const createAdmin = async (formDt) => {
  const url = "https://www.innovations.co.ke/portalapi/public/api/createAdmin";
  const options = {
    method: "POST",
    body: formDt,
  };
  const response = await fetch(url, options);
  if (response.status !== 200) {
    throw new Error("There was an error creating Admin :" + response.status);
  }
  const data = await response.json();
  return data;
};

const AdminCreate = () => {
  return (
    <div className="card col-md-4 mx-auto" style={{ marginTop: "20%" }}>
      <div className="card-header text-center bg-white">
        <h1>
          <span className="text-danger fw-bolder">Create </span>
          <span className="text-info fst-italic">Admin</span>
        </h1>
      </div>
      <div className="card-body">
        <form id="frmCreateAdmin" className="d-grid gap-3">
          <div className="form-group" id="formLogin">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              className="form-control"
              autoComplete="off"
              id="email"
              name="email"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              className="form-control"
              autoComplete="off"
              id="password"
              name="password"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Name</label>
            <input
              type="text"
              className="form-control"
              autoComplete="off"
              id="name"
              name="name"
              required
            />
          </div>
          <button
            type="button"
            id="submit"
            onClick={adminFunc}
            className="btn btn-secondary mx-auto fs-5"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default AdminCreate;
