import LI from "./LI";

const MainNavigation = () => {
  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <LI text="Home" to="/" />
              <LI text="Create Admin" to="/create-admin" />
              <LI text="Create Client" to="/create-client" />
              <LI text="Create Client Contact" to="/create-client-contact" />
              <LI text="Create Developer" to="/create-developer" />
              <LI text="Create Developer Role" to="/create-developer-role" />
              <LI text="Create Role" to="/create-role" />
              <LI text="Create Company Type" to="/create-type" />
              <LI text="Create Priority" to="/create-priority" />
              <LI text="Create Manager" to="/create-manager" />
            </ul>
          </div>
        </div>
      </nav>
      <div className="col-md-12" id="alert_placeholder"></div>
    </div>
  );
};

export default MainNavigation;
