import { useState, useEffect } from "react";
import { showAlert } from "../components/Helpers/Utils";


const devRoleFunc = () => {
  const formData = new FormData(document.getElementById("frmCreateDevRole"));
  createDev(formData)
    .then((data) => {
      showAlert(data, "success");
    })
    .catch((error) => {
      console.log(error);
    });
};

const createDev = async (formDt) => {
  const url = "https://www.innovations.co.ke/portalapi/public/api/createDeveloperRoles";
  const options = {
    method: "POST",
    body: formDt,
  };
  const response = await fetch(url, options);
  if (response.status !== 200) {
    throw new Error("There was an error creating Admin :" + response.status);
  }
  const data = await response.json();
  return data;
};

const DeveloperRoleCreate = () => {
  const [loadedDevs, setLoadedDevs] = useState([]);
  const [loadedRoles, setLoadedRoles] = useState([]);

  useEffect(() => {
    viewDevs()
      .then((data) => {
        setLoadedDevs(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  useEffect(() => {
    viewRoles()
      .then((data) => {
        setLoadedRoles(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const viewDevs = async () => {
    const response = await fetch("https://www.innovations.co.ke/portalapi/public/api/viewDeveloper");
    if (response.status !== 200) {
      throw new Error("There was an error creating Admin :" + response.status);
    }
    const data = await response.json();
    return data;
  };
  const viewRoles = async () => {
    const response = await fetch("https://www.innovations.co.ke/portalapi/public/api/viewRoles");
    if (response.status !== 200) {
      throw new Error("There was an error creating Admin :" + response.status);
    }
    const data = await response.json();
    return data;
  };

  return (
    <div className="card col-md-4 mx-auto" style={{ marginTop: "20%" }}>
      <div className="card-header text-center bg-white">
        <h1>
          <span className="text-danger fw-bolder">Create </span>
          <span className="text-info fst-italic">Developer Role</span>
        </h1>
      </div>
      <div className="card-body">
        <form id="frmCreateDevRole" className="d-grid gap-3">
          <div className="form-group">
            <label htmlFor="developer_id">Developers</label>
            <select
              className="form-select"
              name="developer_id"
              id="developer_id"
            >
              <option value="">Select Developer</option>
              {loadedDevs.map((dt) => {
                return (
                  <option key={dt.developer_id} value={dt.developer_id}>
                    {dt.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="role_id">Roles</label>
            <select className="form-select" name="roles_id" id="roles_id">
              <option value="">Select Role</option>
              {loadedRoles.map((dt) => {
                return (
                  <option key={dt.roles_id} value={dt.roles_id}>
                    {dt.role}
                  </option>
                );
              })}
            </select>
          </div>

          <button
            type="button"
            id="submit"
            onClick={devRoleFunc}
            className="btn btn-secondary mx-auto fs-5"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default DeveloperRoleCreate;
