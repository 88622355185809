import { showAlert } from "../components/Helpers/Utils";
  
  
const typeFunc = () => {
  const formData = new FormData(document.getElementById("frmCreateType"));
  createType(formData)
    .then((data) => {
     showAlert(data, "success")
    })
    .catch((error) => {
      console.log(error);
    });
};

const createType = async (formDt) => {
  const url = "https://www.innovations.co.ke/portalapi/public/api/createType";
  const options = {
    method: "POST",
    body: formDt,
  };
  const response = await fetch(url, options);
  if (response.status !== 200) {
    throw new Error("There was an error creating Client :" + response.status);
  }
  const data = await response.json();
  return data;
};

const Role = () => {
  return (
    <div className="card col-md-4 mx-auto" style={{ marginTop: "20%" }}>
      <div className="card-header text-center bg-white">
        <h1>
          <span className="text-danger fw-bolder">Create </span>
          <span className="text-info fst-italic">Company Type</span>
        </h1>
      </div>
      <div className="card-body">
        <form id="frmCreateType" className="d-grid gap-3">
          <div className="form-group">
            <label htmlFor="role">Type</label>
            <input
              type="text"
              className="form-control"
              autoComplete="off"
              id="type"
              name="type"
              required
            />
          </div>
          <button
            type="button"
            id="submit"
            onClick={typeFunc}
            className="btn btn-secondary mx-auto fs-5"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default Role;
